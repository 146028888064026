<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-form ref="formJustificativa" v-model="valido" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline pl-3">{{ $tc('title.finalizar_apuracoes') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row v-if="apuracoesInconsistentes.length">
              <v-col cols="12" sm="12">
                <span class="font-weight-medium">{{ `${$tc('label.atencao').toUpperCase()}: ` }}</span>
                <span>{{ $tc('message.finalizar_apuracao_inconsistente') }}</span>
                <span v-html="apuracoesInconsistentesFormatadas"/>
              </v-col>
            </v-row>
            <v-row v-show="indApuracoesSelecionadas">
              <v-col cols="12" sm="12">
                <span v-html="`${$t('message.preencha_data_contabilizacao_apuracoes')}`"></span>
              </v-col>
              <v-col cols="12" sm="12">
                <v-menu
                  ref="data_contabilizacao"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      id="data_contabilizacao"
                      class="CalendarioForm-data__text"
                      readonly
                      clearable
                      :rules="[rules.required]"
                      v-model="dataContabilizacaoFormatada"
                      :label="`${$t('label.data_contabilizacao')} *`"
                      prepend-icon="event">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    color="primary"
                    scrollable
                    @input="setaDataFormatada()"
                    v-model="dataContabilizacao">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12">
                <span>{{ $tc('message.finalizar_apuracao_lote') }}</span>
              </v-col>
            </v-row>
            <v-row v-show="!indApuracoesSelecionadas">
              <v-col cols="12" sm="12">
                <span>{{ $tc('message.apuracao_nao_pode_ser_finalizada') }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="indApuracoesSelecionadas" color="primary" text @click="close">{{ $t('label.nao') }}</v-btn>
          <v-btn v-else color="primary" text @click="close">{{ $t('label.ok') }}</v-btn>
          <v-btn color="primary" text v-if="!bloquearJustificativa && indApuracoesSelecionadas"
                 @click="afirmativo()">{{ textoBotaoAfirmativo ? textoBotaoAfirmativo : $t('label.sim') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { salvarApuracaoDataContabilizacao } from '@/common/resources/apuracao-contrato';
import { forceLoading, forceHideLoading } from '../../../common/functions/loading';

export default {
  name: 'DialogFinalizarApuracoes',
  props: {
    title: '',
    textoBotaoAfirmativo: null,
    apuracoesInconsistentes: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      valido: false,
      dialog: false,
      bloquearJustificativa: true,
      justificativa: '',
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      dataContabilizacaoFormatada: null,
      dataContabilizacao: new Date().toJSON().substr(0, 10),
      apuracoesSelecionadas: [],
    };
  },
  computed: {
    apuracoesInconsistentesFormatadas() {
      const textoPorApuracao = [];
      textoPorApuracao.push('<ul>');
      this.apuracoesInconsistentes.forEach((a) => {
        textoPorApuracao.push(`<li>${a.idContrato} - ${a.idAcao} - ${a.idApuracao}</li>`);
      });
      textoPorApuracao.push('</ul>');

      return this.apuracoesInconsistentes.length ? textoPorApuracao.join('') : this.$t('message.nenhuma_apuracao_identificada');
    },
    indApuracoesSelecionadas() {
      return this.apuracoesSelecionadas && this.apuracoesSelecionadas.length;
    },
  },
  methods: {
    open(apuracoesSelecionadas) {
      this.apuracoesSelecionadas = apuracoesSelecionadas;

      this.dialog = true;
      this.bloquearJustificativa = false;
      this.justificativa = '';
    },
    close() {
      this.justificativa = '';
      this.dialog = false;
      this.dataContabilizacaoFormatada = null;
      this.apuracoesSelecionadas = [];
      this.$emit('cancelar');
    },
    salvarDataContabilizacao() {
      if (this.dataContabilizacao) {
        const { dataContabilizacao } = this;
        const idsApuracao = [];
        this.apuracoesSelecionadas.forEach((a) => {
          if (typeof a.idApuracao === 'string' && a.idApuracao.includes(',')) {
            idsApuracao.push(...a.idApuracao
              .split(',')
              .map((id) => parseInt(id, 10)));
          } else {
            idsApuracao.push(a.idApuracao);
          }
        });

        const params = { apuracoes_selecionadas: idsApuracao, data_contabilizacao: dataContabilizacao };
        forceLoading();
        salvarApuracaoDataContabilizacao(params, this.$resource)
          .then((response) => {
            this.apuracoesSelecionadas.push(...response.data);
            this.$emit('agree', this.dataContabilizacao);
            this.dialog = false;
          })
          .catch((err) => {
            this.$error(this, err);
            this.dialog = false;
            forceHideLoading();
          });
      } else {
        this.$emit('agree');
        this.dialog = false;
      }
    },
    setaDataFormatada() {
      this.dataContabilizacaoFormatada = this.retornaDataFormatada(this.dataContabilizacao);
    },
    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    async afirmativo() {
      if (this.dataContabilizacaoFormatada === null) {
        return;
      }
      await this.salvarDataContabilizacao();
    },
  },
};
</script>
