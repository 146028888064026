<template>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card>
      <v-card-title style="word-break: break-word; width: 97%;">
        {{ $tc('title.acao_externa', 1) }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          id="dialog-acao-externa-codigo"
          index="1"
          ref="textacaoExternaCodigo"
          name="Acao_externa_codigo"
          v-model="codAcaoExterna"
          rows="1"
          class="mt-4"
          :label="`${$t('label.id')} *`"
          :counter="64"
          maxlength="64">
        </v-text-field>
        <v-container fluid px-0 py-0>
          <v-row v-if="codAcaoExternaParcial">
            <v-col cols="12" >
              <v-data-table
                :headers="headers"
                :items="acaoFornecedorExterna"
                item-key="acoes"
                :options.sync="pagination"
                :server-items-length="totalPage"
                class="elevation-0"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                }">
                <template v-slot:[`item.periodo`]="{ item }">
                  {{formatarPeriodo(item)}}
                </template>
                 <template v-slot:[`item.codAcaoExternaParcial`]="{ item }">
                  {{item.codAcaoExternaParcial}}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="close()">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="salvar()" color="primary" :disabled="!podeSalvar">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import moment from 'moment';
import {
  buscarAcaoExternaFornecedor,
  salvarAcaoExternaFornecedor,
} from '../../../common/resources/apuracao-contrato';
import { forceLoading, forceHideLoading } from '../../../common/functions/loading';

export default {
  data() {
    return {
      dialog: false,
      pagination: {},
      acaoFornecedorExterna: {},
      idApuracao: null,
      codAcaoExterna: null,
      codAcaoExternaParcial: null,
      totalPage: 10,
      headers: [
        {
          text: this.$tc('label.periodo', 2), value: 'periodo', class: 'nowrap', cellClass: 'nowrap', sortable: false,
        },
        { text: this.$tc('label.acao_externa_id', 2), value: 'codAcaoExternaParcial', sortable: false },
      ],
    };
  },
  computed: {
    podeSalvar() {
      return !!this.codAcaoExterna;
    },
  },
  methods: {
    open(idApuracao) {
      this.dialog = true;
      this.idApuracao = idApuracao;
      if (idApuracao) {
        forceLoading();
        this.buscarAcaoExternaFornecedor(idApuracao);
      }
    },
    close() {
      this.dialog = false;
    },
    buscarAcaoExternaFornecedor(idApuracao) {
      buscarAcaoExternaFornecedor({ idApuracao }, this.$resource)
        .then((response) => {
          if (response.body) {
            this.acaoFornecedorExterna = response.body;
            forceHideLoading();
          }
          this.codAcaoExterna = this.acaoFornecedorExterna[0].codAcaoExterna;
          this.codAcaoExternaParcial = this.acaoFornecedorExterna[0].codAcaoExternaParcial;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formatarPeriodo(item) {
      const dataInicioFormatada = moment(item.dataInicio, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      const dataFimFormatada = moment(item.dataFim, 'DD/MM/YYYY').format('MMMM/YY').toUpperCase();
      if (dataInicioFormatada !== dataFimFormatada) {
        return `${dataInicioFormatada} a ${dataFimFormatada}`;
      }
      return dataInicioFormatada;
    },
    salvar() {
      const apuracaoAcaoAcaoExterna = {
        cod_apuracao: this.idApuracao,
        cod_fornecedor_acao: this.codAcaoExterna ? this.codAcaoExterna.trim() : null,
      };
      return salvarAcaoExternaFornecedor(apuracaoAcaoAcaoExterna, this.$resource)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.close();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
<style lang="scss">
